:root {
    --mainColor: #08C;
    --footerColor: #222529;
    --bodyColor: #fff;
    --headerColor: var(--mainColor);
    --headerFontColor: #bde1f5;
    --footerFontColor: #a8a8a8;
    --footerTitleColor: #fff;
    --footerIconColor: #fff;
}

@import './css/demo2';
@import './css/vendor/fontawesome-free/css/all.min';
@import './css/vendor/simple-line-icons/css/simple-line-icons.min';


.banners-grid{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    height: 920px;

    .banner{
        img{
            width: 100%;
            height: 100%;
        }
        &:nth-child(1){
            grid-column: 1/4;
            grid-row: 1/4;
            text-align: right;
            h3{
                font-size: 24px;
            }
            .btn{
                padding: 12px 20px;
                font-size: 12px;
                margin-top: 10px;
            }
        }
        &:nth-child(2){
            grid-column: 4/10;
            grid-row: 1/5;
            text-align: right;

            h3{
                font-weight: 600;
                font-size: 2.25em;
            }

            a{
                margin-top: 10px;
            }
        }
        &:nth-child(3){
            grid-column: 10/13;
            grid-row: 1/4;
            text-align: right;

            h3{
                font-size: 1.0625em;
                font-weight: 600;
            }

            .btn{
                padding: 12px 20px;
                font-size: 12px;
                margin-top: 10px;
            }
            .description{
                font-size: 0.9375em;
                color: #a2a2a2;
                line-height: 1.25;
                font-weight: 500;
            }
        }
        &:nth-child(4){
            grid-column: 1/4;
            grid-row: 4/9;
            text-align: right;

            .banner-layer-middle{
                top: 37%;
            }
            h3{
                font-size: 1.72em;
                font-weight: 600;
            }
            .description{
                font-size: 0.9375em;
                font-weight: 600;
            }
            .btn{
                padding: 0.7em 1.3em 0.73em 1.2em;
                font-size: 0.75em;
                margin-top: 10px;
            }
        }
        &:nth-child(5){
            grid-column: 4/10;
            grid-row: 5/8;

            h3{
                font-size: 1.5em;
                font-weight: 600;
            }
            .btn{
                padding: 0.7em 1.3em 0.73em 1.2em;
                font-size: 0.75em;
                margin-top: 10px;
            }
        }
        &:nth-child(6){
            grid-column: 10/13;
            grid-row: 4/10;
            text-align: center;
            h3{
                font-size: 1.6875em;
                font-weight: 600;
            }
            .banner-layer {
                margin-top: -3px;
                left: 8.3%;
                right: 8.3%;
                bottom: 10.2%;
            }
            .btn{
                padding: 0.7em 1.3em 0.73em 1.2em;
                font-size: 0.75em;
                margin-top: 10px;
            }
        }
        &:nth-child(7){
            grid-column: 1/4;
            grid-row: 9/12;
            text-align: center;
            h3{
                font-size: 1.375em;
                letter-spacing: 0.15em;
                font-weight: 600;
            }
            .btn{
                padding: 0.7em 1.3em 0.73em 1.2em;
                font-size: 0.75em;
                margin-top: 10px;
            }
            .description{
                padding-top: 3px;
                font-size: 0.625em;
                font-weight: 500;
                letter-spacing: 0.2em;
            }
        }
        &:nth-child(8){
            grid-column: 4/10;
            grid-row: 8/12;

            h3{
                font-size: 1.375em;
                letter-spacing: 0.15em;
                font-weight: 600;
            }
            .btn{
                padding: 0.7em 1.3em 0.73em 1.2em;
                font-size: 0.75em;
                margin-top: 10px;
            }
        }
        &:nth-child(9){
            grid-column: 10/13;
            grid-row: 10/12;
            text-align: center;

            h3{
                font-size: 15px;
                font-weight: 600;
                margin-bottom: 0.4rem;
            }
            .btn{
                padding: 0.7em 1.3em 0.73em 1.2em;
                font-size: 0.75em;
                margin-top: 10px;
            }
            .description{
                padding-bottom: 1px;
                font-size: 0.9375em;
                font-weight: 600;
            }
        }
    }
}

.sidebar-overlay{
    display: none;
}

/*.fancybox__container{
    z-index: 1200;
}*/

.social-icons{
    a{
        svg path{
            fill: var(--headerFontColor);
        }
        .footer-icon{
            path{
                fill: var(--footerIconColor);
            }
        }
    }
}

.brands-slider{
    img{
        width: 140px;
        height: 60px;
        object-fit: contain;
    }
}

.share-box{
    display: flex;
    gap: 10px;
    ul{
        display: flex;
        gap: 10px;
        margin: 0 20px 0 0;
    }
}
.product-gallery__featured{
    margin-bottom: 15px;
}
.info-box-img {
    flex-direction: column;
}

.info-box {
    display: flex;
    align-items: center;
    color: #222529;
}
.info-box-wrapper h4 {
    font-weight: 700;
    font-size: 18.2px;
    line-height: 3.2rem;
    margin-bottom: 3px;
}
.info-box-wrapper .info-box-img h4 {
    line-height: 2.7rem;
    margin-top: 1rem;
}
.info-box-wrapper p {
    font-family: Poppins,sans-serif;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #777;
}
.info-box-wrapper .info-desc {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 1rem;
    color: #999;
}
.info-box-img{
    img{
        height: 230px;
        object-fit: cover;
    }
    margin-bottom: 30px;
}
.service-card{
    img{
        width: 100%;
        object-fit: cover;
    }
}
.product-wrapper{
    display: flex;
    margin-bottom: 20px;
    img{
        height: 80px;
        width: 80px;
        object-fit: cover;
        margin-right: 12px;
    }

    h4{
        font-size: 20px;
        color: #3D464D;
    }
}
.mob-td{
    display: none;
}
.product-row{
    td{
        vertical-align: middle;
    }
}

.cart-total{
    span{
        margin-left: 10px;
        font-weight: 600;
        font-size: 20px;
    }
}
@media(max-width: 767px){
    .table-cart{
        .product-row{
            display: block;
            width: 100%;
            border: 1px solid #dee2e6;
            padding: 10px;
    
            td{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                text-align: center;
                border: none;
            }
            .mob-td{
                display: inline;
                font-weight: 600;
                font-size: 15px;
                margin-right: 10px;
            }
        }
        thead{
            display: none;
        }
    }
}

#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}

.footer{
    margin-top: auto;
}

@media(max-width: 767px){
    .banners-grid{
        height: auto;
        display: block;
    }
}
.not-found {
    text-align: center;
  }
  
  .not-found__404 {
    font-size: 80px;
    font-weight: 700;
    color: #ebebeb;
    padding: 40px 0 20px;
  }
  
  .not-found__content {
    width: 480px;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .not-found__title {
    margin-bottom: 30px;
  }
  
  .not-found__text {
    margin-bottom: 20px;
  }
  
  .not-found__search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
  }
  
  .not-found__search-input {
    width: 1px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
   .not-found__search-input {
    margin-right: 10px;
  }
  [dir=rtl] .not-found__search-input {
    margin-left: 10px;
  }
  
  @media (max-width: 991px) {
    .not-found__404 {
      font-size: 60px;
    }
  }
@media (max-width: 767px) {
    .not-found__404 {
      font-size: 50px;
      line-height: 54px;
      padding: 40px 0 32px;
    }
}

.control-white{
    color:#fff;

    &::placeholder{
        color:#777;
    }
}

.product-default figure img{
    height: 265px;
    object-fit: cover;
}
.preloader{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.432);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.isLoading {
    pointer-events: none;
    opacity: .5;
    position: relative;
    min-height: 100px;
  
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid var(--mainColor);
      border-left-color: transparent;
      animation: rotate .5s infinite linear;
      z-index: 99;
    }
  
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
}

.pd0{
    padding: 0;
}
.project-image {
    overflow: hidden;
    width: 100%;
}
.project-image img{
    height: 350px;
    object-fit: cover;
    transition: .5s;
}
.project-item:hover .project-image img {
    transform: scale(1.1);
}
@media (max-width: 1600px){
    .project-image img {
        height: 300px;
    }
}
@media (max-width: 1340px){
    .project-image img {
        height: 260px;
    }
}
@media (max-width: 1200px){
    .project-image img {
        height: auto;
    }
}
.checkout-form{
    .modal-header{
        border: none;
    }
}
.form-success{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: .3s;

    i{
        font-size: 40px;
        color: #31D84F;
    }
}

.form-visible{
    opacity: 1;
    visibility: visible;
}
.mini-product figure{
    img{
        width: 75px;
        height: 75px;
    }
}
.footer{
    background-color: var(--footerColor);
}
.form-loading{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    left: 0;
    background-color: #ffffff70;
}
.category-img{
    width: 25px;
    height: 25px;
    object-fit: contain;
    margin-right: 4px;
}
.cursor{
    cursor: pointer;
}